<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div style="width: 100%;margin-top:10px">
          <el-form-item label="核销日期" class="col-auto">
            <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
              size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期"></el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-input style="width: 315px" size="medium" placeholder="请输入关键词" v-model="keyword" clearable>
              <el-select slot="prepend" v-model="key_word" style="width: 120px">
                <el-option value="trade_sn" label="订单编号"></el-option>
                <el-option value="name" label="商品名称"></el-option>
                <el-option value="member_mobile" label="下单用户手机号"></el-option>
                <el-option value="verify_member_mobile" label="核销人手机号"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item class="col-auto">
            <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
            <el-button :loading="importLoading" @click="exportNotice" type="primary" size="small">导出</el-button>
          </el-form-item>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="订单编号" prop="trade_sn" width="180" fixed="left" />
        <el-table-column label="图片" width="60" class-name="goods-cover-wrapper" fixed="left">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.image" style="width: 300px;" alt />
              <img slot="reference" :src="row.image" class="goods-cover" alt />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="name" show-overflow-tooltip width="200" fixed="left">
          <template slot-scope="{row}">
            {{ row.name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
          </template>
        </el-table-column>
        <el-table-column label="核销金额" prop="money" width="180" />
        <el-table-column label="销售价" width="100">
          <template slot-scope="{row}">
            <span>{{ row.shop_price | unitPrice('￥') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下单用户手机号" prop="member_mobile" width="140" />
        <el-table-column label="核销人手机号" prop="verify_member_mobile" width="140" />
        <el-table-column min-width="120" label="核销时间">
          <template slot-scope="{row}">
            <span>{{ row.create_time | unixToDate }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="120" label="核销码" prop="verify_code" />
        <el-table-column min-width="220" label="自提地址">
          <template slot-scope="scope">
            <span>{{ scope.row.selected_pickup_station_add }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="220" label="自提日期">
          <template slot-scope="scope">
            <span>{{ timeFilter(scope.row.pickup_time, 'datatime') }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="120" label="自提时间">
          <template slot-scope="scope">
            <span>{{ timeFilter(scope.row.pickup_time, 'time') }}</span>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import { getVerifyRecordList } from '@/api/order';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import { handleDownload } from '@/utils';
import { Foundation } from "@/../ui-utils";
export default {
  name: 'verificationList',
  components: { EnTableLayout },
  data () {
    return {
      importLoading: false,
      keyword: "",
      key_word: "trade_sn",
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page: 1,
        size: 20
      },
      /** 高级搜索数据 */
      advancedForm: {
        order_time_range: [],
      },
      /** 列表数据 */
      tableData: {},
    };
  },
  mounted () {
    this.GET_AccountList();
  },
  methods: {
    /**格式化时间 */
    timeFilter (data, type) {
      const time = JSON.parse(data)
      let start = time.starting_date
      let end = time.ending_date
      let formateDate
      if (type == 'time') {
        formateDate = time.time_slot.split('_')
        formateDate = formateDate[0] + ' ' + formateDate[1]
      } else {
        formateDate = Foundation.unixToDate(start, 'yyyy-MM-dd') + ' ' + Foundation.unixToDate(end, 'yyyy-MM-dd')
      }
      return formateDate
    },
    /** 搜索事件触发 */
    searchEvent () {
      delete this.params.trade_sn;
      delete this.params.name;
      delete this.params.member_mobile;
      delete this.params.verify_member_mobile;
      this.params[this.key_word] = this.keyword;
      delete this.params.start;
      delete this.params.end;
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          this.params.start =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.end =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.end =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      delete this.params.order_time_range;
      this.params.page = 1;
      this.GET_AccountList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page = page;
      this.GET_AccountList();
    },
    GET_AccountList () {
      this.loading = true;
      let params = this.formData(this.params)
      getVerifyRecordList(params).then(response => {
        this.loading = false;
        this.tableData = response;
      });
    },
    infoNotice (row) {
      this.$router.push({
        path: `/order/order-list/detail/${row.trade_sn}`,
        query: {
          type: 1
        }
      })
    },
    formData (data) {//格式化表单
      let params = JSON.parse(JSON.stringify(data));
      params = Object.fromEntries(Object.entries(params).filter(([key, value]) => value))
      return params;
    },
    //导出
    exportNotice () {
      this.importLoading = true;
      let filterVals = [
        "trade_sn",
        // "image",
        "name",
        "money",
        "shop_price",
        "member_mobile",
        "verify_member_mobile",
        "create_time",
        "verify_code",
        "selected_pickup_station_add",
        "datatime",
        "time",
      ]
      let tHeaders = [
        "订单编号",
        // "图片",
        "商品名称",
        "核销金额",
        "销售价",
        "下单人手机号",
        "核销人手机号",
        "核销时间",
        "核销码",
        "自提地址",
        "自提日期",
        "自提时间"
      ]
      let params = this.formData(this.params)
      params.page = 1;
      params.size = 99999;
      getVerifyRecordList(params).then((response) => {
        response.data.forEach(item => {
          item.create_time = Foundation.unixToDate(item.create_time)
          item.datatime = this.timeFilter(item.pickup_time, 'datatime')
          item.time = this.timeFilter(item.pickup_time, 'time')
        });
        handleDownload(response.data, tHeaders, filterVals, '核销记录');
        this.importLoading = false;
      })
        .catch((res) => {
          this.importLoading = false;
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

.dialog-footer {
  text-align: center;
  margin: 20px 0;
}
</style>
